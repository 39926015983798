import { Order } from './order';
import { Payment } from './payment';
import { Shop } from './shop';
import { ReceiptPaper } from './receipt_paper';
import { TmpOrder } from './tmp_order';
import { Effect } from '../menu';
import { Seat } from './table';

export class TableSession {
  id: string;
  shop_id: string;
  quantities: number;
  table_no: number;
  no: number;
  status: number;
  category_type_flg: number;
  is_takeout: boolean;
  is_quick: boolean;
  receipt_no: string|null;
  receipt_time: Date|null;
  receive_time_from: Date|null;
  receive_time_to: Date|null;
  is_receive: boolean|null;
  is_hidden: boolean|null;

  shop: Shop;
  deviceCodes: {
    [key: string]: {
      exclude: number|null;
      include: number|null;
      quantity: number|null;
      tax: number|null;
      tax_rate: number;
    }[];
  };
  noPayment: {
    exclude: number|null;
    include: number|null;
    quantity: number|null;
    tax: number|null;
    tax_rate: number;
  }[];
  no_paid_orders: Order[];
  paymented: {[key: string]:{
    tax_rate: number|null,
    include: number,
    quantity: number,
  }[]};
  payments: Payment[];
  tmp_orders: TmpOrder[];
  receipt_papers: ReceiptPaper[];

  created: Date;
  table_session_effects?: Effect[];
  table_seat?: Seat
}

export enum Status {
  // 状態: 受付
  STATUS_RECEPTED = 0,
  // 状態: 精算済
  STATUS_PAID = 1,
  // 状態: 支払い確認中
  STATUS_UNPAID = 2,
  STATUS_PAYING = 3,
  // 状態: 取消
  STATUS_CANCELED = -1,
  // 状態: 無効
  STATUS_INVALID = -2,
}
