































































































































































































































import Vue from "vue";
import _ from "lodash";
import notificationMixin from "../../mixin/notification";
import { Status, TableSession } from "../../model/entity/table_session";
import { ContractPlans } from "../../model/entity/shopSetting";
import ShopAPI from "../../common/api/api_shop";
import store from "../../store/shop";
import { TranslateServiceBuilder } from "../../services/Translate/translateServiceBuilder";

const translate = TranslateServiceBuilder.Instance()._;
const api = new ShopAPI();

export default Vue.extend({
  components: {
    SHPrinterError: () =>
      import("../../components/components/SHPrinterError.vue"),
    SHTakeoutCard: () =>
      import("../../components/components/SHTakeoutCard.vue"),
    SHTakeoutCardSkeleton: () =>
      import("../../components/components/SHTakeoutCardSkeleton.vue"),
    QButton: () => import("../../components/elements/QButton.vue"),
    QTranslate: () => import("../../components/elements/QTranslate.vue"),
  },
  mixins: [notificationMixin],
  data() {
    return {
      searchWord: "",
      currentItem: {},
      errors: "",
      mode: "",

      isLoading: false,

      staffId: store.state.staff.id,
    };
  },
  mounted() {
    api.set_token(store.state.token);
    this.fetchTableSessions();
  },
  computed: {
    details() {
      return store.state.printerStatus;
    },
    isTakeout(): boolean {
      const setting = store.state.shopSetting;
      if (setting === null) {
        return false;
      }
      if (store.state.shopSetting.contract_plan !== ContractPlans.TAKEOUT) {
        return false;
      }

      return store.state.shopSetting.printer_plan;
    },
    items(): TableSession[] {
      return store.state.takeoutSessions;
    },
    isOpen(): boolean {
      return store.state.takeoutIsOpen;
    },
    isModalCancel(): boolean {
      return this.mode === "cancel";
    },
    isModalCancelDone(): boolean {
      return this.mode === "cancelDone";
    },
    isModalUndo(): boolean {
      return this.mode === "undo";
    },
  },
  methods: {
    handleClickTakeout: function () {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.submitOpen();
    },
    handleClickEdit: function (id) {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.mode = "";
      this.submitItem(id);
    },
    handleClickCloseEditor: function () {
      this.closeEditor();
    },
    handleClickEditUndo: function (item) {
      this.mode = "undo";
      this.currentItem = item;
    },
    handleClickCancel: function (item) {
      this.mode = "cancel";
      this.currentItem = item;
    },
    handleClickSubmit: function (id) {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.mode = "";
      this.submitCancel(id, false);
    },
    handleClickSubmitTempo: function (id) {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.mode = "";
      this.submitCancel(id, true);
    },
    handleClickSearch: function (e) {
      if (e.keyCode !== 13) {
        return;
      }
      this.fetchTableSessions();
    },
    handleClickResetSearch: function () {
      this.searchWord = "";
      this.fetchTableSessions();
    },

    submitItem: function (id) {
      let message = "";
      api
        .takeout_edit({ table_session_id: id })
        .then((response) => {
          if (response.data.status !== "success") {
            message =
              response.data.message ||
              translate("hall-0046", "保存に失敗しました。");
            this.showErrorNotification(message);
            this.errors = message;
            this.isLoading = false;
            return;
          }
          message = response.data.message;
          this.showSuccessNotification(message);
          this.fetchTableSessions();
        })
        .catch((err) => {
          message =
            err.response.data.message ||
            translate("hall-0046", "保存に失敗しました。");
          this.showErrorNotification(message);
          this.errors = message;
          this.isLoading = false;
        });
    },

    submitCancel: function (id, flug) {
      let message = "";
      api
        .takeout_delete(
          { staffId: this.staffId, isTempo: flug },
          { table_session_id: id }
        )
        .then((response) => {
          if (response.data.status !== "success") {
            message = response.data.message || "キャンセル処理に失敗しました。";
            this.showErrorNotification(message);
            this.errors = message;
            this.isLoading = false;
            return;
          }
          this.mode = "cancelDone";
          message = response.data.message;
          this.showSuccessNotification(message);
          this.fetchTableSessions();
        })
        .catch((err) => {
          message =
            err.response.data.message || "キャンセル処理に失敗しました。";
          this.showErrorNotification(message);
          this.errors = message;
          this.isLoading = false;
        });
    },

    submitOpen: function () {
      let message = "";
      api
        .takeout_change_flag({ staffId: this.staffId })
        .then((response) => {
          if (response.data.status !== "success") {
            message =
              response.data.message ||
              translate("hall-0046", "保存に失敗しました。");
            this.showErrorNotification(message);
            this.errors = message;
            this.isLoading = false;
            return;
          }
          message = response.data.message;
          this.showSuccessNotification(message);
          this.fetchTableSessions();
        })
        .catch((err) => {
          message =
            err.response.data.message ||
            translate("hall-0046", "保存に失敗しました。");
          this.showErrorNotification(message);
          this.errors = message;
          this.isLoading = false;
        });
    },

    closeEditor: function () {
      this.mode = "";
      this.isLoading = false;
    },

    fetchTableSessions() {
      this.isLoading = true;
      let message = "";

      store.dispatch("fetchTakeoutSearch", { word: this.searchWord });
      store.dispatch("fetchTakeoutSessions");

      this.isLoading = false;
    },

    isCancel(value): boolean {
      return value === Status.STATUS_CANCELED;
    },
    isShowPayment(payment): boolean {
      if (payment.customer != null) {
        if (payment.payment_details.length > 0 && payment.payment_details[0].type === 'refunds') {
          return false;
        }
        if (payment.type === 'refunds') {
          return false;
        }
        return true;
      }
      return false;
    }
  },
  filters: {
    changeStatus: function (flg, loadingFlg) {
      if (loadingFlg === true) {
        return "";
      }
      if (flg === true) {
        return "open";
      }
      return "close";
    },
    shopStatus: function (flg) {
      if (flg === true) {
        return translate("hall-0016", "受付中");
      }
      return translate("hall-0015", "停止中");
    },
    toStatusName: function (item) {
      if (item.is_receive === true) {
        return "end";
      }
      if (item.status === Status.STATUS_CANCELED) {
        return "cancel";
      }
      return "";
    },
  },
});
